<template>
  <b-container fluid class="my-3">
    <b-row>
      <b-col sm="3"  >
        <b-row>
          <b-col>
            <b-card class="shadow my-1">
              <div class="d-flex justify-content-between">
                <div v-if="!SearchLoading" class="w-100">
                  <b-row>
                    <b-col class="my-auto">
                      <h6 class="mb-2 text-center">
                        {{ $t("Item Count") }} :
                        <strong>{{ items.length }} </strong>
                      </h6>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <SidePage
          v-if="!SearchLoading"
          @Change-Search="ChangeSearch"
          :max="maxProdPrice"
        ></SidePage>
      </b-col>

      <b-col sm="9">
        <b-skeleton-wrapper :loading="SearchLoading">
          <template #loading>
            <b-container fluid class="my-4">
              <SearchLoader></SearchLoader>
            </b-container>
          </template>
          <b-card
            class="justify-content-center bg-white my-2"
            v-if="SearchEmpty"
          >
            <div class="text-center mb-5">
              <h2 style="color: #f6601a" class="my-4 mb-2">
                {{ $t("SearchErr") }}
              </h2>
              <h4>{{ $t("SearchErr_1") }}</h4>
            </div>
          </b-card>

          <b-row v-if="!SearchLoading" id="my-cards">
            <ProdCard
              v-for="Product in itemsForList"
              v-bind:key="Product.id"
              v-bind:Product="Product"
              :per-page="perPage"
              :current-page="currentPage"
              class="col-sm-3 col-6 p-1"
            ></ProdCard>
          </b-row>
          <b-row v-if="waitReady" align-h="center">
            <b-pagination
              class="my-3 p-0"
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              aria-controls="my-cards"
            ></b-pagination>
          </b-row>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { mapActions } from "vuex";
import ProdCard from "../components/items/ProdCard.vue";
import SearchLoader from "../components/widgets/SearchLoader.vue";
import SidePage from "../components/widgets/SidePage.vue";
export default {
     metaInfo: {
    title: "Search",
  },
  computed: {
    ...mapGetters(["Categories", "Tags"]),
    rows() {
      return this.items.length;
    },
    itemsForList() {
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  components: {
    SidePage,
    ProdCard,
    SearchLoader,
  },
  watch: {
    "$route.params.term": function () {
      this.DoSearch();
          var itemID = this.$route.params.term;
      var message = "Search By Item " + itemID + " Page";
      this.$gtag.event(message, { method: "Google" });

    },
    items() {
      this.SearchLoading = false;
    },
  },
 
  methods: {
    ...mapActions(["getProdBySer"]),
    ChangeSearch(sform) {
      this.searchform = sform;
      this.DoSearch();
    },

    DoSearch: function () {
      this.$Progress.start();

      var searchTerm = this.$route.params.term;
      var curr = this.$cookies.get("wmc_current_currency");
      var ship = this.$cookies.get("shipCountry");
      var url =
        process.env.VUE_APP_DEVBASEURL +
        "/Search/" +
        searchTerm +
        "/" +
        this.searchform.limit +
        "/" +
        curr +
        "/" +
        ship +
        "/" +
        this.searchform.condition +
        "/" +
        this.searchform.maxprice +
        "/" +
        this.searchform.minprice +
        "/" +
        this.searchform.highRate +
        "/" +
        this.searchform.lowRate;
      axios.get(url).then((resp) => {
        if (resp.data.status) {
          this.items = resp.data.items;
          this.maxProdPrice = resp.data.max;
          this.$Progress.finish();
          this.waitReady = true;
          if (this.items.length == 0) {
            this.SearchEmpty = true;
          } else {
            this.SearchEmpty = false;
          }
        }
      });
    },
  },
  data() {
    return {
      perPage: 8,
      currentPage: 1,
      searchform: {
        minprice: 0,
        maxprice: 2000,
        highRate: 5,
        lowRate: 0,
        limit: 20,
        sort: "DESC",
        condition: "New",
      },
      maxProdPrice: 0,

      SearchLoading: true,
      SearchEmpty: false,
      items: [],
      pageOfItems: [],
      waitReady: false,
      customLabels: {
        first: this.$t("first"),
        last: this.$t("last"),
        previous: this.$t("previous"),
        next: this.$t("next"),
      },
    };
  },
  created() {
    this.DoSearch();
  },
};
</script>

<style>
.pagination {
  margin-right: auto !important;
  margin-left: auto !important;
}
li.first {
  display: none !important;
}
li.last {
  display: none !important;
}
</style>